<template>
  <!-- Coming soon page-->
  <b-overlay
    :show="showLoading"
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div class="misc-wrapper">
      <b-link class="brand-logo">
        <vuexy-logo />
      </b-link>
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1 font-weight-bolder">
            We are launching soon 🚀
          </h2>
          <p class="mb-3">
            We are creating something awesome, register your email & get onboard!
          </p>
          <validation-observer
            ref="emailField"
          >
            <!-- form -->
            <b-form
              class="row justify-content-center m-0 mb-2"
              @submit.prevent
            >
              <b-row>
                <!-- Must be a valid email -->
                <b-col md="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                      mode="passive"
                    >
                      <b-form-input
                        v-model="emailValue"
                        :state="errors.length > 0 ? false:null"
                        placeholder="sara@example.com"
                      />
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                  class="mt-2"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                    class="align-center btn-sm-block"
                    type="submit"
                    @click="createPotentialAccount"
                  >
                    Verify Email
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <b-img
            fluid
            :src="imgUrl"
            alt="Coming soon page"
          />
          <b-row class="mt-2">
            <b-col md="12">
              <vac :end-time="new Date('2022-02-01').getTime()">
                <template
                  v-slot:process="{ timeObj }"
                >
                  <h3 class="font-weight-normal">
                    <b-row>
                      <b-col>
                        {{ timeObj.d }}
                        <br>
                        <h6>Days</h6>
                      </b-col>
                      <b-col>
                        {{ timeObj.h }}
                        <br>
                        <h6>Hours</h6>
                      </b-col>
                      <b-col>
                        {{ timeObj.m }}
                        <br>
                        <h6>Minutes</h6>
                      </b-col>
                      <b-col>
                        {{ timeObj.s }}
                        <br>
                        <h6>Seconds</h6>
                      </b-col>
                    </b-row>
                  </h3>
                </template>
                <template
                  v-slot:finish
                >
                  <span>Done!</span>
                </template>
              </vac>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- toast -->
      <b-toast
        id="success-toast"
        variant="primary"
        :auto-hide-delay="10000"
      >
        <template #toast-title>
          <div class="d-flex flex-grow-1 align-items-center mr-1">
            <strong class="mr-auto">Almost there!</strong>
          </div>
        </template>
        <span>Verify your email to complete your email registration</span>
      </b-toast>
    </div>
  </b-overlay>
<!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BForm, BButton, BImg, BCol, BFormGroup, BFormInput, BRow, BToast, BOverlay,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BButton,
    BImg,
    BCol,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BRow,
    BToast,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      emailValue: '',
      showLoading: false,
      downImg: require('@/assets/images/pages/doctors-prescription-image-family.jpg'),
      required,
      email,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'JO',
        },
      },
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/doctors-prescription-image-family.jpg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    createPotentialAccount() {
      this.$refs.emailField.validate().then(success => {
        if (success) {
          this.showLoading = true
          this.$store.dispatch('comingSoon/createPotentialUser', this.emailValue)
            .then(() => {
              this.$bvToast.show('success-toast')
              this.emailValue = ''
              this.showLoading = false
            })
            .catch(() => {
              this.$bvToast.toast('Email address is already registered', {
                title: 'Registration Failure',
                variant: 'danger',
                solid: true,
                autoHideDelay: 10000,
              })
              this.showLoading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
